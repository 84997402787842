import { basePathRewrite, getAbsoluteImgPath } from '@components/common';
import { Button, FormGroup } from '@license-admin/boldfjcomponents';
import {
  HeadMessage,
  HeadWrap,
  RefineHeadLabel,
  SearchInputWrap,
  SearchResultTips,
  SearchWrap,
  TroubleCheckList,
  TroubleCheckListItem,
} from './styled';

interface Props {
  isSearchPage?: boolean;
}

const EmptySearchResult = (props: Props) => {
  const { isSearchPage } = props;

  const resetAll = () => {
    document.location.href = window.location.pathname;
  };

  return (
    <>
      {!isSearchPage ? (
        <>
          <h3>
            Sorry, we couldn&apos;t find any results. We recommend you either:
          </h3>
          <ul>
            <li>
              <a href="#" onClick={() => resetAll()} role="button">
                Start Over
              </a>
            </li>
          </ul>
        </>
      ) : (
        <>
          <HeadWrap>
            <img
              src={getAbsoluteImgPath('/images/icons/magnifier-x.svg')}
              width="30"
              alt="Search Icon"
            />
            <HeadMessage>
              Sorry, we couldn&apos;t find any results for jobs that Full-Time,
              Part-Time, Flexible Schedule, Alternative Schedule; are of job
              types: Temporary, Freelance, Employee; 100% Remote, Option for
              Remote; Entry-Level; have titles Associate Trial Attorney; are in
              CareerBliss 50 Happiest Companies in America; Travel Required: No.
            </HeadMessage>
          </HeadWrap>
          <SearchWrap>
            <RefineHeadLabel>Refine your search:</RefineHeadLabel>
            <SearchInputWrap>
              <FormGroup
                type="text"
                id="refine-search"
                placeholder="Enter Keywords or Title"
                formGrpClass="refine-search-inpt"
              />
              <Button
                id="submit-search"
                icon="fa fa-search"
                aria-label="Search"
                isIconRight
                ButtonClass="refine-btn"
              ></Button>
            </SearchInputWrap>
          </SearchWrap>
          <SearchResultTips>
            <h4>Having trouble finding what you want?</h4>
            <TroubleCheckList>
              <TroubleCheckListItem>
                Check your spelling and try again.
              </TroubleCheckListItem>
              <TroubleCheckListItem>
                Keep your search term simple.
              </TroubleCheckListItem>
              <TroubleCheckListItem>
                Try our{' '}
                <a href={basePathRewrite('/searchOptions.aspx')}>
                  Advanced Search
                </a>
                .
              </TroubleCheckListItem>
              <TroubleCheckListItem>
                Browse our{' '}
                <a href={basePathRewrite('/remote-jobs')}>Job Categories</a>.
              </TroubleCheckListItem>
              <TroubleCheckListItem>
                <a href={basePathRewrite('/company-guide')}>
                  Research Companies
                </a>
                that are known to offer flexible work options.
              </TroubleCheckListItem>
              <TroubleCheckListItem>
                Check out our{' '}
                <a href={basePathRewrite('/blog/')}>
                  Job Search Tips &amp; Blog
                </a>
                .
              </TroubleCheckListItem>
              <TroubleCheckListItem>
                Find inspiration with our list of{' '}
                <a href={basePathRewrite('/jobs/surprising-jobs')}>
                  100 of the Most Surprising Flexible Jobs
                </a>
                .
              </TroubleCheckListItem>
              <TroubleCheckListItem>
                Need assistance?{' '}
                <a href={basePathRewrite('/contact')}>Contact Us</a>.
              </TroubleCheckListItem>
            </TroubleCheckList>
          </SearchResultTips>
        </>
      )}
    </>
  );
};

export default EmptySearchResult;
