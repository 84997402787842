import { formatSalaryRange } from '@components/common';
import SaveJobComponent from '@components/saveJobComponent';
import { IJobCardDeviceProps } from '@components/shared.types';
import { DateDifference, jobPostedDateFormatted } from '@utils/dateFormatter';
import { isRCPortal, visitDirect } from 'helper';
import { useEffect, useState } from 'react';
import {
  CompnayNameInfoWrap,
  DeleteIcon,
  ExpiredText,
  Featured,
  HeadWrap,
  IconWraps,
  JobAge,
  JobCompany,
  LocationSpan,
  MiddleWrap,
  MutedTitle,
  NewTag,
  TagList,
  TagListWrap,
  TagsWrap,
  Title,
  TitleWrap,
  Wrapper,
} from '../styled';

const JobCard = (props: IJobCardDeviceProps) => {
  const { savedJob, openHostedJob, isDynamicBreadcrumb } = props;

  const {
    featured,
    id,
    company,
    title,
    postedDate,
    jobTypes,
    salaryRange,
    allowedCandidateLocation,
    expireOn,
    isFreeJob = false,
    slug,
  } = props.jobsData;

  const [expireOnDate, setexpireOnDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    setexpireOnDate(new Date(expireOn));
  }, []);

  return (
    <Wrapper
      noSpace={props.isPastJobs}
      isNoImage={true}
      isActive={props.isActive}
      featured={featured}
      onClick={props.handleCardClick}
      onAuxClick={(e) => (openHostedJob ? openHostedJob(e, id) : undefined)}
      id={id}
      isRCPortal={isRCPortal}
    >
      <div>
        {featured && (
          <Featured id={'featured-' + id} isRCPortal={isRCPortal}>
            {isRCPortal ? (
              <span className="star-icons">&#9733;</span>
            ) : (
              <i className="fa fa-star"></i>
            )}{' '}
            Featured
          </Featured>
        )}

        <HeadWrap>
          <TitleWrap
            isRCPortal={isRCPortal}
            isPremiumUser={props.isPremiumUser}
          >
            {props.isCompanyPage ? (
              <>
                {!props.isPastJobs ? (
                  <Title
                    id={'title-' + id}
                    href={`/job-details/${slug}`}
                    blurTest={false}
                    rel={!isFreeJob ? 'nofollow' : undefined}
                  >
                    {props.hideJobTitle
                      ? 'Company Name Here Company Name Here Company Name Here'.substring(
                          0,
                          title.length
                        )
                      : title}
                  </Title>
                ) : (
                  <MutedTitle
                    isPastJob={props.isPastJobs}
                    id={'subtitle-' + id}
                  >
                    {props.hideJobTitle
                      ? 'Company Name Here Company Name Here Company Name Here'.substring(
                          0,
                          title.length
                        )
                      : title}
                  </MutedTitle>
                )}
                {company && (
                  <JobCompany
                    id={'company-name-' + id}
                    isRCPortal={isRCPortal}
                    data-isFreeJob={props.isFreeJob}
                    data-index={props.customAttribute}
                  >
                    {company.name}
                  </JobCompany>
                )}
              </>
            ) : (
              <>
                <CompnayNameInfoWrap>
                  <Title
                    href={
                      `/job-details/${slug}` + visitDirect(isDynamicBreadcrumb)
                    }
                    rel={!isFreeJob ? 'nofollow' : undefined}
                    blurTest={false}
                    id={'job-name-' + id}
                    className="textWrap"
                  >
                    {title}
                  </Title>
                  {DateDifference(String(postedDate)).daysDifference < 7 && (
                    <NewTag id={'new-' + id} isRCPortal={isRCPortal}>
                      New!
                    </NewTag>
                  )}
                </CompnayNameInfoWrap>

                {expireOn &&
                  expireOnDate &&
                  expireOnDate < new Date() &&
                  props.localizationJobs && (
                    <ExpiredText id={'expired-' + id}>
                      {props.localizationJobs.expired_date_message}{' '}
                      {jobPostedDateFormatted(String(expireOn))}
                    </ExpiredText>
                  )}
                {company && (
                  <>
                    <JobCompany
                      id={'company-name-' + id}
                      isRCPortal={isRCPortal}
                      data-isFreeJob={props.isFreeJob}
                      data-index={props.customAttribute}
                    >
                      {company.name}
                    </JobCompany>
                  </>
                )}
              </>
            )}
          </TitleWrap>

          <JobAge id={'job-age-' + id}>
            {(!props.isFreeJob || props.isPremiumUser) && (
              <SaveJobComponent
                showIcon={true}
                saved={savedJob}
                jobId={id}
                savedJobsArray={props.savedJobsArray}
                setSavedJobsArray={props.setSavedJobsArray}
                jobDetails={props.jobsData}
                custumAttribute={props.customAttribute}
                jobNumber={props.jobNumber}
                jobsData={props.jobsList}
                guid={props.guid}
                postedDate={postedDate}
              />
            )}
            <span id={'date-diff-' + id}>
              {DateDifference(String(postedDate)).differenceResp}
            </span>
          </JobAge>
        </HeadWrap>
        <MiddleWrap
          isBadgeSuccess={isRCPortal}
          isPremiumUser={props.isPremiumUser}
        >
          <TagsWrap
            isPremiumUser={props.isPremiumUser}
            wrapTag={
              (props.totalTagsCount > 3 && props.activeDetailId !== '') ||
              props.totalTagsCount > 5
            }
          >
            {props.remoteOptionsFilter &&
              props.remoteOptionsFilter.length > 0 && (
                <TagList
                  isPremiumUser={props.isPremiumUser}
                  isTagName
                  className="tag-name"
                  key={'remoteoption' + id}
                  id={'remoteoption-' + 0 + '-' + id}
                  isBadgeSuccess={isRCPortal}
                >
                  {props.remoteOptionsFilter[0]}
                </TagList>
              )}
            {props.jobSchedulesData && props.jobSchedulesData.length > 0 && (
              <TagList
                isPremiumUser={props.isPremiumUser}
                isTagName
                className="tag-name"
                key={'jobschedule' + id}
                id={'jobschedule-' + 0 + '-' + id}
                isBadgeSuccess={isRCPortal}
              >
                {props.jobSchedulesData[0]}
              </TagList>
            )}

            {jobTypes && jobTypes.length > 0 && (
              <TagList
                isPremiumUser={props.isPremiumUser}
                isTagName
                className="tag-name"
                key={'jobtypes' + id}
                id={'jobTypes-' + 0 + '-' + id}
                isBadgeSuccess={isRCPortal}
              >
                {jobTypes[0]}
              </TagList>
            )}

            {salaryRange && salaryRange.length > 0 && (
              <TagList
                isPremiumUser={props.isPremiumUser}
                isTagName
                className="tag-name"
                key={'tag' + 0}
                id={'salartRange-' + 0 + '-' + id}
                isBadgeSuccess={isRCPortal}
              >
                {formatSalaryRange(salaryRange)}
              </TagList>
            )}

            {props.isSuggestedJobsPage && (
              <DeleteIcon
                id={'delete-btn-' + id}
                onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                  props.deleteJob(e);
                }}
                className="fa fa-trash-can"
              />
            )}
          </TagsWrap>
        </MiddleWrap>
        {
          <>
            {' '}
            {allowedCandidateLocation && (
              <TagListWrap>
                <TagList
                  isPremiumUser={!props.isPremiumUser}
                  isLocationFlag
                  className="allowed-location-flag"
                  key={'tag'}
                  id={'allowedlocation-flag-' + id}
                  isBadgeSuccess={isRCPortal}
                >
                  <IconWraps>
                    <span id={'location-info-' + id}>
                      <i
                        className="fa fa-location-dot"
                        title="Candidates need to be able to work in the City or State specified"
                      ></i>
                    </span>
                  </IconWraps>
                  {allowedCandidateLocation.map((tag: any, index: number) => {
                    const tags =
                      tag?.toLowerCase().search('work from anywhere') === 0
                        ? null
                        : tag;
                    return (
                      <LocationSpan
                        className="allowed-location"
                        key={'tag' + index}
                        id={'allowedlocation-' + index + '-' + id}
                      >
                        {typeof props?.JobWorkType !== 'undefined' && (
                          <>{`${props?.JobWorkType(
                            props.remoteOptionsFilter[index],
                            tag
                          )} `}</>
                        )}

                        {tags}
                        {allowedCandidateLocation.length - 1 != index
                          ? allowedCandidateLocation.join(',')
                          : ''}
                      </LocationSpan>
                    );
                  })}
                </TagList>
              </TagListWrap>
            )}
          </>
        }
      </div>
    </Wrapper>
  );
};

export default JobCard;
