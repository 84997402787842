import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';
interface WrapStyle {
  widthWrap?: boolean;
  isSearchPage?: boolean;
  isRCPortal?: boolean;
  isNotPremiumUser?: boolean;
  isLegitimate?: boolean;
  isCategoryPage?: boolean;
  isCompanyComponent?: boolean;
}

export const Wrapper = styled.div<WrapStyle>`
  ${({
    widthWrap,
    isSearchPage,
    isRCPortal,
    theme: { JobDetailsStyle },
    isNotPremiumUser,
    isLegitimate,
    isCategoryPage,
    isCompanyComponent,
  }) =>
    !widthWrap &&
    css`
      max-width: ${isRCPortal ? '100%' : '60rem'};
      margin: ${isCompanyComponent ? '-5rem 0 0' : 0};
      overflow: hidden auto;
      height: calc(
        100vh - ${isRCPortal ? '4rem' : isSearchPage ? '19.5rem' : '23.7rem'}
      );
      position: sticky;
      top: ${isRCPortal ? '2rem' : isSearchPage ? '19.5rem' : '23.7rem'};
      z-index: 10;
      padding: ${isRCPortal ? '0 2.4rem 2.4rem' : '0 1.6rem 2.4rem'};
      ${Mixin.CustomScroll()}
    `};
  ${({ theme: { JobDetailsStyle }, widthWrap, isRCPortal }) =>
    JobDetailsStyle &&
    css`
      border-color: ${JobDetailsStyle.BdrColors.wrapper};
      background: ${JobDetailsStyle.BgColors.wrapper};
      border: ${!widthWrap &&
      `0.2rem solid ${JobDetailsStyle.BdrColors.wrapperActive}`};
      border-radius: ${isRCPortal ? '0.4rem' : ' 0.8rem'};
    `}
`;

export const ButtonCloseInset = styled.button`
  box-sizing: content-box;
  width: 0.6rem;
  height: 0.6rem;
  padding: 0.25em 0.25em;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  ${Mixin.Position({ value: '1rem 1.6rem auto auto' })};
  z-index: 1;
  cursor: pointer;
  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      color: ${JobDetailsStyle.Colors.btn};
    `}
`;

export const WriteNote = styled.a`
  padding: 1rem 0;
  display: block;
  font-weight: 400;
  margin-top: 0.6rem;
  i {
    margin-right: 1rem;
  }
`;

export const VisitLinkWrap = styled.div`
  margin-bottom: 2rem;
`;

export const JobTitle = styled.h1<{ blurTest?: boolean; isRCPortal?: boolean }>`
  margin-bottom: 0;
  ${({ isRCPortal, theme: { JobDetailsStyle } }) =>
    isRCPortal &&
    css`
      font-size: ${JobDetailsStyle?.FontSize?.jobTitleNew};
      font-family: ${JobDetailsStyle?.FontFamily?.jobTitleNew};
      font-weight: 700;
    `}
  ${({ blurTest }) =>
    blurTest &&
    css`
      filter: blur(0.8rem);
    `}
  ${device.mobileLg} {
    ${({ theme: { JobDetailsStyle } }) =>
      JobDetailsStyle &&
      css`
        font-size: ${JobDetailsStyle.FontSize.jobTitleSm};
        font-weight: 600;
      `}
  }
`;
export const VisitLink = styled.a`
  margin-bottom: 0.25rem;
  display: block;
`;

export const AboutJobRole = styled.div`
  padding-bottom: 1.3rem;
  margin-top: 0.7rem;
  h3 {
    font-weight: 700;
  }
`;
export const AboutTitle = styled.div`
  font-weight: 600;
  padding: 0 0 1.4rem 0;
  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      font-size: ${JobDetailsStyle.FontSize.aboutTitle};
      color: ${JobDetailsStyle.Colors.aboutTitle};
    `}
`;
export const BlurText = styled.div`
  filter: blur(0.5rem);
  min-height: 2rem;
  overflow: hidden;
  max-height: 4rem;
`;
export const PreviousPage = styled.div<{ noTopSpace?: boolean }>`
  margin: ${({ noTopSpace }) => (noTopSpace ? '-1.5rem' : '0 -1.5rem')};
  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      box-shadow: 0rem 1rem 0.3rem 0rem
        ${JobDetailsStyle.BoxShadow.previousText};
      background: ${JobDetailsStyle.BgColors.previousText};
    `}
  padding: 0.8rem 1.5rem;
  margin-bottom: 2rem;
`;

export const BackPage = styled.div`
  margin: -2rem -2.4rem 2rem;
  border-bottom: 0.1rem solid #ddd;
  padding: 0.8rem 1.5rem;
`;
export const PreviousPageInner = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      font-size: ${JobDetailsStyle.FontSize.previousText};
      color: ${JobDetailsStyle.Colors.previousText};
    `}
`;
export const JobDate = styled.p<{ isVariant?: boolean }>`
  margin: 0;
  padding: 0;
  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      font-size: ${JobDetailsStyle.FontSize.jobDate};
      color: ${JobDetailsStyle.Colors.jobDate};
    `}
  ${({ isVariant }) =>
    isVariant &&
    css`
      ${device.mobileLg} {
        text-align: right;
      }
    `}
`;
export const RightSideLinks = styled.div`
  text-align: right;
`;
export const ImageLink = styled.a<{ isRCPortal?: boolean }>`
  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      &:hover span {
        color: ${JobDetailsStyle.Colors.approvedHover};
      }
    `}
`;
export const ApprovedWrap = styled.span`
  padding: 1.2rem 0.6rem;
  ${Mixin.AllCenter({ direction: 'column' })}
  max-width: 8.5rem;
  border-radius: 0.3rem;
  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      border: 0.1rem solid ${JobDetailsStyle.BdrColors.imageCard};
    `}
`;
export const ApprovedText = styled.span`
  text-align: center;
  line-height: 1;
  padding-top: 0.8rem;
  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      color: ${JobDetailsStyle.Colors.approvedText};
      font-size: ${JobDetailsStyle.FontSize.approvedText};
    `}
`;
export const TabsWrapperContainer = styled.div`
  margin-bottom: 0;
`;

export const JobTime = styled.span`
  display: block;
  text-align: right;

  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      color: ${JobDetailsStyle.Colors.jobTime};
      font-size: ${JobDetailsStyle.FontSize.jobTime};
    `}
`;

export const DetailWrapper = styled.div`
  ${Mixin.GridLayout({ gridDimension: '80% 18%' })};
`;

export const TitleImageWrap = styled.div<{
  isPremiumUser?: boolean;
  isCompanyImg?: boolean;
}>`
  ${({ isPremiumUser, isCompanyImg }) =>
    (isPremiumUser || isCompanyImg) &&
    Mixin.GridLayout({
      gridDimension: '5.8rem 1fr',
      alignItems: 'center',
      columnGap: '1.6rem',
      rowGap: '0',
    })};
  margin-bottom: ${({ isPremiumUser, isCompanyImg }) =>
    (isPremiumUser || isCompanyImg) && '1.6rem'};
`;

export const CompImageWrapper = styled.div`
  > img {
    width: 5.8rem;
    height: 5.8rem;
    object-fit: contain;
  }
`;

export const CompName = styled.h2<{ isRCPortal?: boolean }>`
  margin-bottom: 0;
  ${({ theme: { JobDetailsStyle }, isRCPortal }) =>
    JobDetailsStyle &&
    css`
      ${isRCPortal &&
      css`
        font-size: ${JobDetailsStyle?.FontSize?.compName};
        font-family: ${JobDetailsStyle?.FontFamily?.compName};
        font-weight: 700;
      `}
      color: ${JobDetailsStyle.Colors.compName};
    `}
`;

export const StickyWrapper = styled.div<{
  isPremiumUser?: boolean;
  isHybrid?: boolean;
  isHostedPage?: boolean;
  hasShadow?: boolean;
}>`
  padding: ${({ isHostedPage, isPremiumUser, isHybrid }) =>
    isHostedPage
      ? isHybrid
        ? '2rem 0 2rem'
        : '0 0 2rem'
      : isPremiumUser || isHybrid
      ? '2.4rem 2.4rem 1.6rem'
      : '2.4rem 0 1.4rem'};

  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      background-color: ${JobDetailsStyle.BgColors.stickyWrap};
    `}

  ${({
    theme: { JobDetailsStyle },
    isPremiumUser,
    isHybrid,
    hasShadow,
    isHostedPage,
  }) =>
    JobDetailsStyle &&
    (isPremiumUser || isHybrid) &&
    css`
      ${device.mediumScreen} {
        ${Mixin.Position({
          position: isHostedPage ? 'static' : 'sticky',
          value: '0 auto auto',
        })};
        z-index: 2;
        margin: ${!isHostedPage && '0 -2.4rem'};
        box-shadow: ${hasShadow &&
        `0 0.4rem 0.4rem 0${JobDetailsStyle.BoxShadow.stickyWrapShadow}`};
      }

      ${device.mobileLg} {
        padding: 0 0 2.4rem;
      }
    `}
`;

export const ButtonWrapper = styled.div`
  ${Mixin.VerticalCenter()}
  column-gap: 1rem;

  ${({ theme: { JobDetailsStyle } }) =>
    JobDetailsStyle &&
    css`
      .save-job-btn {
        min-width: ${JobDetailsStyle?.Sizes?.saveJobBtn};
        padding: ${JobDetailsStyle?.Spacing?.saveJobBtn};
        line-height: 1;
        ${device.mobileLg} {
          font-size: ${JobDetailsStyle.FontSize.saveJobBtn};
        }
      }
    `}
`;

export const HostedBtnWrapper = styled.div<{
  isStickyBtn?: boolean;
  isRCPortal?: boolean;
}>`
  ${Mixin.GridLayout({ gridCount: 2, mobDimension: 'repeat(2,1fr)' })};
  column-gap: 1rem;
  ${({ theme: { JobDetailsStyle }, isStickyBtn, isRCPortal }) =>
    JobDetailsStyle &&
    css`
      .save-job-btn {
        min-width: ${!isRCPortal && '14rem'};
        line-height: ${!isRCPortal && '1.5'};

        ${device.mobileLg} {
          font-size: ${(isStickyBtn) =>
            isStickyBtn ? '1.6rem' : `${JobDetailsStyle.FontSize.saveJobBtn}`};
          &:hover {
            background-color: transparent;
            color: #ff532a;
          }
        }
      }
    `}
`;

export const StickyButtonWrapper = styled.div<{ isSticky: boolean }>`
  ${Mixin.Position({ position: 'fixed', value: 'auto auto 0 0' })};
  z-index: 99;
  width: 100%;
  padding: 2.4rem 1.9rem 1.6rem 1rem;
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 1rem;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 0.4rem 0 #00000080;
  transform: ${({ isSticky }) =>
    isSticky ? 'translateY(0)' : 'translateY(105%)'};

  &.isSticky {
    transform: translate(0);
  }

  .save-job-btn {
    height: 100%;
    ${({ theme: { JobDetailsStyle } }) =>
      JobDetailsStyle &&
      css`
        font-size: ${JobDetailsStyle.FontSize.stickyButtonWrapper};
      `}
  }
`;

export const StickyButtonNewWrapper = styled.div<{ isSticky: boolean }>`
  ${Mixin.Position({ position: 'sticky', value: 'auto auto 0 0' })};
  z-index: 99;
  width: 100%;
  padding: 2.4rem 1.9rem 1.6rem 1rem;
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 1rem;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 0.4rem 0 #00000080;
  width: 100vw;
  margin: 0 -2.4rem;
  transform: ${({ isSticky }) =>
    isSticky ? 'translateY(0)' : 'translateY(185%)'};

  &.isSticky {
    transform: translate(0, 2.5rem);
  }

  .save-job-btn {
    height: 100%;
    ${({ theme: { JobDetailsStyle } }) =>
      JobDetailsStyle &&
      css`
        font-size: ${JobDetailsStyle.FontSize.stickyButtonNewWrapper};
        ${device.mobileLg} {
          &:hover {
            background-color: transparent;
            color: #ff532a;
          }
        }
      `}
  }
`;
export const DetailStickyWrap = styled.div<{ isSticky?: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  margin: 0 -2.4rem;
  background-color: #fff;
  border-bottom: 0.1rem solid #ddd;
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;

  transform: ${({ isSticky }) =>
    isSticky ? 'translateY(-2.5rem)' : 'translateY(-100%)'};
`;

export const CloseIcon = styled.i`
  color: #8e8d8f;
`;
export const Subheading = styled.h2<{ isRCPortal?: boolean }>`
  margin-bottom: ${({ isRCPortal }) =>
    isRCPortal &&
    css`
      ${isRCPortal ? '1.6rem' : '1.2rem'}
    `};
  i {
    margin-right: 1rem;
  }
  ${({ isRCPortal, theme: { JobInfoStyle } }) =>
    isRCPortal &&
    css`
      font-size: ${JobInfoStyle?.FontSizes?.subHeading};
      font-weight: ${JobInfoStyle?.FontWeight?.descNew};
    `}
`;
