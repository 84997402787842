import { useState } from 'react';
import {
  Description,
  DescriptionWrapper,
  Heading,
  ViewMoreBtn,
  Wrapper,
} from './styled';

interface Props {
  heading: string;
  description: string;
  isCompanypage?: boolean;
  topSpace?: boolean;
  isCategoryPage?: boolean;
  isMobileView?: boolean;
  isExperiment?: boolean;
  jobDescriptionPage?: any;
  isLoggedin?: boolean;
}

const HeaderSection = ({
  heading,
  description,
  isCompanypage,
  topSpace,
  isCategoryPage,
  isMobileView,
  isExperiment,
  jobDescriptionPage,
  isLoggedin,
}: Props) => {
  const VIEW_TEXT = {
    more: 'See More',
    less: 'See Less',
  };
  const [viewMore, setViewMore] = useState(VIEW_TEXT.more);
  const [descriptionShort, setDescriptionShort] = useState(
    description.slice(0, 123) + ' ...'
  );
  const handleViewMore = (e: any) => {
    e.preventDefault();
    if (viewMore == VIEW_TEXT.less) {
      setViewMore(VIEW_TEXT.more);
      setDescriptionShort(description.slice(0, 123) + ' ...');
    }
    if (viewMore == VIEW_TEXT.more) {
      setViewMore(VIEW_TEXT.less);
      setDescriptionShort(description);
    }
  };
  const removeHtmlTags = (str: string) => str.replace(/<\/?[^>]+(>|$)/g, '');

  return (
    <Wrapper isCompanypage={isCompanypage} topSpace={topSpace}>
      {isCategoryPage ? (
        <>
          {isExperiment && (
            <>
              {jobDescriptionPage(
                isMobileView
                  ? removeHtmlTags(descriptionShort)
                  : removeHtmlTags(description)
              )}
              {isMobileView && !isLoggedin && (
                <ViewMoreBtn
                  href="#"
                  onClick={(e) => handleViewMore(e)}
                  isTextMore={viewMore == VIEW_TEXT.more}
                >
                  {viewMore}
                </ViewMoreBtn>
              )}
            </>
          )}
          {!isExperiment && description && (
            <DescriptionWrapper>
              <Description
                dangerouslySetInnerHTML={{
                  __html: isMobileView ? descriptionShort : description,
                }}
                isCategoryPage={isCategoryPage}
              />
              {isMobileView && (
                <ViewMoreBtn
                  href="#"
                  onClick={handleViewMore}
                  isTextMore={viewMore == VIEW_TEXT.more}
                >
                  {viewMore}
                </ViewMoreBtn>
              )}
            </DescriptionWrapper>
          )}
          {heading && (
            <Heading
              dangerouslySetInnerHTML={{ __html: heading }}
              isCategoryPage={isCategoryPage}
            ></Heading>
          )}
        </>
      ) : (
        <>
          {heading && (
            <Heading dangerouslySetInnerHTML={{ __html: heading }}></Heading>
          )}
          {description && (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default HeaderSection;
