import { getAbsoluteImgPath } from '@components/common';
import ApplyButton from '@components/common/applyButton';
import JobInfo from '@components/jobInfo';
import SaveJobComponent from '@components/saveJobComponent';
import { IJobDetailsDeviceProps } from '@components/shared.types';
import { jobPostedDateFormatted } from '@utils/dateFormatter';
import { isRCPortal, portalLogo } from 'helper';
import {
  AboutJobRole,
  ApprovedText,
  ApprovedWrap,
  BlurText,
  ButtonCloseInset,
  ButtonWrapper,
  CompImageWrapper,
  CompName,
  DetailWrapper,
  ImageLink,
  JobTime,
  JobTitle,
  StickyWrapper,
  Subheading,
  TitleImageWrap,
  Wrapper,
} from '../styled';
import LazyImage from 'helper/useLazyLoadImages';

const JobDetails = (props: IJobDetailsDeviceProps) => {
  const {
    hasBoxShadow,
    applyPopupRef,
    companyLogoUrl,
    savedJobsCommons,
    jobDetails,
    isHybrid,
    companyDetails,
    showJobDetailButton,
    isHostedPage,
    isSearchPage,
    isCompanyPage,
    deviceType,
    hideSaveJobButton,
    hideNote,
    hideCopyReport,
    unlockBannerSrc,
    isLegitimate,
    isCategoryPage,
    isCompanyComponent,
    JobWorkType,
    isExperiment
  } = props;

  return (
    <Wrapper
      {...props}
      ref={props.targetElement}
      isSearchPage={isSearchPage}
      id="inset-wrapper"
      isRCPortal={isRCPortal}
      isNotPremiumUser={!props.isPremiumUser}
      isLegitimate={isLegitimate}
      isCategoryPage={isCategoryPage}
      isCompanyComponent={isCompanyComponent}
    >
      {props.hideDetails && !isHybrid && !props.isPremiumUser && (
        <ButtonCloseInset
          onClick={props.hideDetails}
          type="button"
          aria-label="Close job details view"
          id="close-inset-detail"
          aria-hidden="true"
        />
      )}
      <StickyWrapper
        isHybrid={isHybrid}
        isHostedPage={isHostedPage}
        hasShadow={hasBoxShadow}
      >
        <DetailWrapper>
          <TitleImageWrap isCompanyImg={isHybrid}>
            {isHybrid && (
              <ImageLink
                id={`company-link-${jobDetails.id}`}
                href={`/remote-jobs/company/${jobDetails.company.slug}`}
                target="_blank"
                rel="external"
              >
                {companyLogoUrl ? (
                  <CompImageWrapper>
                    <LazyImage
                      imgSrc={companyLogoUrl}
                      imgAlt={jobDetails.company.name}
                      imgId={`company-img-${jobDetails.id}`}
                    />
                  </CompImageWrapper>
                ) : (
                  <ApprovedWrap>
                    <img
                      width={74}
                      height={33}
                      id={`company-img-${jobDetails.id}`}
                      src={getAbsoluteImgPath(`/images/${portalLogo}`)}
                      alt={jobDetails.company.name}
                    />
                    {!isRCPortal && (
                      <ApprovedText>
                        {props.localization.approved_employer}
                      </ApprovedText>
                    )}
                  </ApprovedWrap>
                )}
              </ImageLink>
            )}
            <div>
              <JobTitle
                blurTest={isCompanyPage && !isHybrid}
                isRCPortal={isRCPortal}
              >
                {props.jobDetails.title}
              </JobTitle>
              {props.jobDetails?.company && isHybrid && (
                <CompName isRCPortal={isRCPortal}>
                  {jobDetails.company.name}
                </CompName>
              )}
            </div>
          </TitleImageWrap>

          <JobTime>
            {jobPostedDateFormatted(String(props.jobDetails.postedDate))}
          </JobTime>
        </DetailWrapper>

        {isHybrid && (
          <>
            <ButtonWrapper>
              <ApplyButton
                jobId={props.jobId}
                isJobAlreadyApplied={false}
                applyURL={jobDetails.applyURL}
                applyJobStatus={jobDetails.applyJobStatus}
                applyPopupRef={applyPopupRef}
                isHostedPage={props.isHostedPage}
                jobDetails={props.jobDetails}
                jobNumber={props.jobNumber}
                jobsData={props.jobsData}
                queryParams={props.queryParams}
                guid={props.guid}
                buttonSize={isHostedPage ? 'lg' : 'md'}
                isHybrid={isHybrid}
                localization={props.localization}
                isLoggedIn={props.isLoggedIn}
              />
              {!hideSaveJobButton && (
                <SaveJobComponent
                  jobId={props.jobId}
                  {...savedJobsCommons}
                  isHostedPage={isHostedPage}
                  mediumButton
                  jobDetails={props.jobDetails}
                  jobNumber={props.jobNumber}
                  jobsData={props.jobsData}
                  guid={props.guid}
                />
              )}
            </ButtonWrapper>
          </>
        )}
      </StickyWrapper>

      <JobInfo
        jobDetails={jobDetails}
        jobId={props.jobId}
        companyDetails={companyDetails}
        isHybrid={isHybrid}
        savedJobsCommons={savedJobsCommons}
        showJobDetailButton={showJobDetailButton}
        localization={props.localization}
        referer={props.referer}
        isHostedPage={props.isHostedPage}
        applyPopupRef={applyPopupRef}
        jobNumber={props.jobNumber}
        jobsData={props.jobsData}
        queryParams={props.queryParams}
        guid={props.guid}
        role="tabpanel"
        deviceType={deviceType}
        blurTitle={isCompanyPage && !isHybrid}
        hideNote={hideNote}
        hideCopyReport={hideCopyReport}
        unlockBannerSrc={unlockBannerSrc}
        JobWorkType={JobWorkType}
        isExperiment={isExperiment}
      />

      {!isHybrid && (
        <AboutJobRole>
          {isRCPortal ? (
            <Subheading isRCPortal={isRCPortal}>
              {props.localization.about_role}
            </Subheading>
          ) : (
            <h3>{props.localization.about_role}</h3>
          )}
          <BlurText>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea aut
            maxime iure enim eaque, explicabo beatae minus asperiores natus
            excepturi. Eum eos ea cupiditate placeat, fugit molestiae magni
            iusto ad?
          </BlurText>
        </AboutJobRole>
      )}
    </Wrapper>
  );
};

export default JobDetails;
