import { formatSalaryRange } from '@components/common';
import { remoteJobsCategorySlugs } from '@components/common/constant';
import { DateDifference } from '@utils/dateFormatter';
import { isRCPortal } from 'helper';
import LazyImage from 'helper/useLazyLoadImages';
import {
  ArrowIcon,
  CompanyLogoWrap,
  CompanyTitle,
  ContentBox,
  ImgWrapper,
  JobAge,
  JobDataListType,
  JobLink,
  JobList,
  JobListItem,
  JobListWrapper,
  JobsHeading,
  LocationWrap,
  NewTag,
  SeeAllLink,
  TopWrapper,
  TopWrapperListItem,
} from './styled';

const FreeRemoteJobsList = ({
  JobsList = [],
  isPremiumUser,
  isMobileView,
  JobWorkType,
  isRemoteJobsPage,
}: any) => {
  const categorizedJobs: any = [];

  JobsList.forEach((item: any) => {
    const category = remoteJobsCategorySlugs.find(
      (i) => i.id == Number(item.key)
    );
    if (item.value.length > 0) {
      let obj = {
        ...category,
        jobDetails: item.value,
      };
      categorizedJobs.push(obj);
    }
  });
  const visitDirect = (fromcat: string) => {
    const fromcats = fromcat.replace('/remote-jobs/', '');
    return isRemoteJobsPage ? `?category=${fromcats}` : null;
  };
  return (
    <>
      {categorizedJobs?.map((job: any, index: number) => (
        <ContentBox key={index}>
          <JobsHeading isRCPortal={isRCPortal}>
            <a href={job.headingLink} id={`heading-link-${index + 1}`}>
              {job.heading}
            </a>
          </JobsHeading>

          {job.jobDetails && (
            <JobListWrapper isRCPortal={isRCPortal}>
              {job.jobDetails.map((jobData: any, index: number) => (
                <JobList key={index}>
                  {!isMobileView && (
                    <>
                      {jobData.company.logo ? (
                        <ImgWrapper>
                          <LazyImage
                            imgSrc={jobData.company.logo}
                            imgAlt={`${jobData.title} logo`}
                          />
                        </ImgWrapper>
                      ) : (
                        <CompanyLogoWrap>
                          <LazyImage
                            imgSrc={`/blobcontent/rmc/images/remote-co-logo.svg`}
                            imgAlt={`${jobData.title} logo`}
                            imgWidth={74}
                            imgHeight={33}
                          />
                        </CompanyLogoWrap>
                      )}
                    </>
                  )}

                  <div>
                    <TopWrapper>
                      <TopWrapperListItem>
                        <JobLink
                          href={
                            `/job-details/${jobData.slug}` +
                            visitDirect(job.headingLink)
                          }
                          rel={!jobData?.isFreeJob ? 'nofollow' : undefined}
                        >
                          {jobData.title}
                        </JobLink>
                        {DateDifference(String(jobData.postedDate))
                          .daysDifference < 7 && <NewTag>New!</NewTag>}
                      </TopWrapperListItem>
                      <JobAge>
                        {
                          DateDifference(String(jobData.postedDate))
                            .differenceResp
                        }
                      </JobAge>
                    </TopWrapper>
                    {jobData?.company?.name && (
                      <CompanyTitle isRCPortal={isRCPortal}>
                        {jobData?.company?.name}
                      </CompanyTitle>
                    )}
                    <JobDataListType>
                      {jobData.remoteOptions &&
                        jobData.remoteOptions.length > 0 && (
                          <JobListItem>{jobData.remoteOptions[0]}</JobListItem>
                        )}
                      {jobData.jobSchedules &&
                        jobData.jobSchedules.length > 0 && (
                          <JobListItem>{jobData.jobSchedules[0]}</JobListItem>
                        )}
                      {jobData.salaryRange && (
                        <JobListItem>
                          {formatSalaryRange(jobData.salaryRange)}
                        </JobListItem>
                      )}
                    </JobDataListType>
                    <LocationWrap>
                      <span>
                        <i
                          className="fa fa-location-dot"
                          title="Candidates need to be able to work in the City or State specified"
                        ></i>
                      </span>
                      {jobData.allowedCandidateLocation?.map(
                        (tag: any, index: number) => {
                          const tags =
                            tag?.toLowerCase().search('work from anywhere') ===
                            0
                              ? null
                              : tag;
                          return (
                            <>
                              {typeof JobWorkType !== 'undefined' && (
                                <>{`${JobWorkType(
                                  jobData.remoteOptions[index],
                                  tag
                                )} `}</>
                              )}

                              {tags}
                              {jobData.allowedCandidateLocation.length - 1 !=
                              index
                                ? jobData.allowedCandidateLocation.join(', ')
                                : ''}
                            </>
                          );
                        }
                      )}
                    </LocationWrap>
                  </div>
                </JobList>
              ))}
              <JobList>
                {job.seeAllTitle && (
                  <SeeAllLink
                    href={job.seeAllLink}
                    id={`see-all-link-${index + 1}`}
                    isRCPortal={isRCPortal}
                  >
                    {job.seeAllTitle}
                    <ArrowIcon isRCPortal={isRCPortal} />
                  </SeeAllLink>
                )}
              </JobList>
            </JobListWrapper>
          )}
        </ContentBox>
      ))}
    </>
  );
};

export default FreeRemoteJobsList;
