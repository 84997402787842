import { IJobDetailsProps } from '@components/shared.types';
import { width } from '@styles/device.styled';
import useMediaQuery from '@utils/useMediaQuery';
import { useOutsideClick } from 'helper/hooks/useOutsideClick';
import { useEffect, useRef, useState } from 'react';
import DesktopJobDetails from './Desktop';
import MobileJobDetails from './Mobile';

const JobDetails = (props: IJobDetailsProps) => {
  const {
    jobDetails,
    savedJob,
    jobCardRef,
    paginationRef,
    mainWrapperRef,
    dateButtonRef,
    relevanceButtonRef,
    hideDetails,
    unlockBannerSrc,
    isSticky,
    isLegitimate,
    isCategoryPage,
    isCompanyComponent,
    JobWorkType,
    isExperiment
  } = props;

  const [hasBoxShadow, setHasBoxShadow] = useState<boolean>(false);
  const applyPopupRef = useRef<HTMLDivElement | null>(null);
  const isMobileView =
    useMediaQuery(width.tablet) || props.deviceType === 'mobile';
  const isOutsideClick = useOutsideClick(props.targetElement, mainWrapperRef, [
    jobCardRef,
    applyPopupRef,
    paginationRef,
    dateButtonRef,
    relevanceButtonRef,
  ]);

  const companyLogoUrl =
    jobDetails && jobDetails.company && jobDetails.company.logo;

  const savedJobsCommons = {
    saved: savedJob,
    savedJobsArray: props.savedJobsArray,
    setSavedJobsArray: props.setSavedJobsArray,
  };

  useEffect(() => {
    if (isOutsideClick && hideDetails) {
      hideDetails();
    }
  }, [isOutsideClick]);

  useEffect(() => {
    const shadowOnScroll = () => {
      if (props.targetElement && props.targetElement.current) {
        const scrollTop = props.targetElement.current.scrollTop;
        setHasBoxShadow(scrollTop > 0);
      }
    };

    if (props.targetElement && props.targetElement.current) {
      props.targetElement.current.addEventListener('scroll', shadowOnScroll);
    }

    return () => {
      if (props.targetElement && props.targetElement.current) {
        props.targetElement.current.removeEventListener(
          'scroll',
          shadowOnScroll
        );
      }
    };
  }, []);

  // ! TODO: Rethink over this retrun as in new-jobs page(jobDetails object is empty thats why considering it as a undefined)

  if (!jobDetails) {
    return null;
  } else if (typeof jobDetails === 'object' && jobDetails !== null) {
    if (!jobDetails.company) {
      jobDetails.company = {
        logo: '',
        name: '',
        website: '',
        slug: '',
        description: '',
        accolades: [],
      };
    }
  }

  if (isMobileView) {
    return (
      <MobileJobDetails
        widthWrap
        hasBoxShadow={hasBoxShadow}
        companyLogoUrl={companyLogoUrl}
        savedJobsCommons={savedJobsCommons}
        applyPopupRef={applyPopupRef}
        {...props}
        unlockBannerSrc={unlockBannerSrc}
        isSticky={isSticky}
        JobWorkType={JobWorkType}
        isExperiment={isExperiment}
      />
    );
  } else {
    return (
      <DesktopJobDetails
        hasBoxShadow={hasBoxShadow}
        companyLogoUrl={companyLogoUrl}
        savedJobsCommons={savedJobsCommons}
        applyPopupRef={applyPopupRef}
        {...props}
        unlockBannerSrc={unlockBannerSrc}
        isLegitimate={isLegitimate}
        isCategoryPage={isCategoryPage}
        isCompanyComponent={isCompanyComponent}
        JobWorkType={JobWorkType}
        isExperiment={isExperiment}
      />
    );
  }
};

export default JobDetails;
