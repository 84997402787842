import { useEffect } from 'react';
interface UseIntersectionObserverOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
}
// Intersection Observer leverages browser-optimized viewport detection, avoiding frequent scroll event recalculations.
// Intersection Observer efficiently tracks multiple elements with native batching, unlike scroll events requiring manual throttling.
const useIntersectionObserver = (
  elementIds: string[], // Array of element IDs to observe
  callback: (_elem: IntersectionObserverEntry) => void, // Callback when an element intersects
  options: UseIntersectionObserverOptions = {} // Intersection Observer options
) => {
  useEffect(() => {
    // Ensure all elements are valid DOM elements by resolving IDs
    const elements = elementIds
      .map((id) => document.getElementById(id))
      .filter((el): el is HTMLElement => el !== null); // Type guard for non-null elements
    const observer = new IntersectionObserver(
      (entries: Array<IntersectionObserverEntry>) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) {
            callback(entry);
          }
        });
      },
      options
    );
    // Start observing each element
    elements.forEach((el) => observer.observe(el));
    return () => {
      // Unobserve each element during cleanup
      elements.forEach((el) => observer.unobserve(el));
    };
  }, [elementIds, callback, options]);
};
export default useIntersectionObserver;
